export const GET_ROOM_FEED = "/room/explore";
export const GET_ROOM_ID_FEED = "/room/feed";
export const SET_ROOM_ID = "/room/roomId";
export const GET_AUDIO_ROOM_DATA = "/room";
export const GET_LIVE_ROOM_DATA = "/liveroom";
export const GET_NON_AUTH_TOKEN = "/users/non-auth";
export const GET_CATEGORIES = "/category/all";
export const GET_ROOM_DETAIL = "/room/roomDetail";

export const GET_LEVEL = "/level/user";
export const GET_LEADERBOARD_STATS = "/leaderboard/stats";
export const GET_GARI_LEADERBOARD_STATS = "/leaderboard/gari-bot";
export const GET_LEADERBOARD_STATS_BATTLE = "/leaderboard/battle";
export const GET_LEADERBOARD_STATS_FANS = "/leaderboard/fans/stats";
export const PATCH_TOGGLE_ANONYMOUS = "/leaderboard/anonymous";
export const GET_CALL_PRICE = "/call-price/user";
export const UPDATE_CALL_PRICE = "/call-price/update/user";
export const GET_USER_DETAILS = "/users/userDetail";
export const GET_LIVEROOM_DATA = "/liveroom";
export const GET_ROOM_DATA = "/room";
export const GET_CREATOR_FORM = "/creator-form/userData";
export const ADD_CREATOR_FORM = "/creator-form/submit";
export const GET_ASTRO_LOCATION = "/astro/location";
export const GET_ASTRO_TIMEZONE = "/astro/timezone";
export const GET_ASTRO_SUN_SIGN_PREDICTION = "/astro/sun-sign";
export const GET_ASTRO_HOROSCOPE_PREDICTION = "/astro/horoscope";
export const GET_ASTRO_CHINESE_HOROSCOPE_PREDICTION =
  "/astro/horoscope/chinese";
export const GET_ASTRO_PANCHANG = "/astro/panchang";
export const GET_ASTRO_KUNDLI_MATCH = "/astro/kundli-match";
export const GET_ASTRO_MANGLIK_REPORT = "/astro/manglik-report";
export const GET_ASTRO_FREE_KUNDLI_BASIC = "/astro/free-kundli/basic";
export const GET_ASTRO_FREE_KUNDLI_PLANETS = "/astro/free-kundli/planets";
export const GET_ASTRO_FREE_KUNDLI_CHART = "/astro/free-kundli/chart";
export const GET_ASTRO_FREE_KUNDLI_KP = "/astro/free-kundli/kp";
export const GET_ASTRO_FREE_KUNDLI_ASHTAKVARGA =
  "/astro/free-kundli/ashtakvarga";
export const GET_ASTRO_FREE_KUNDLI_DASHA = "/astro/free-kundli/dasha";
export const GET_ASTRO_FREE_KUNDLI_REPORT = "/astro/free-kundli/report";
export const GET_ASTRO_FREE_REPORT_KAALSARP_YOG =
  "/astro/free-reports/kaalsarp-yog";
export const GET_ASTRO_FREE_REPORT_MANGLIK_REPORT =
  "/astro/free-reports/manglik";
export const GET_ASTRO_FREE_REPORT_SADESATI_REPORT =
  "/astro/free-reports/sadhesati";
export const GET_ASTRO_FREE_REPORT_VIMSHOTTARI_REPORT =
  "/astro/free-reports/vimshottari";
export const GET_ASTRO_FREE_REPORT_GEMSTONES = "/astro/free-reports/gemstones";
export const GET_ASTRO_FREE_REPORT_POOJA = "/astro/free-reports/pooja";
export const GET_ASTRO_FREE_REPORT_NUMEROLOGY =
  "/astro/free-reports/numerology";
export const GET_ASTRO_FREE_REPORT_PITRA_DOSHA =
  "/astro/free-reports/pitra-dosha";
export const GET_ASTRO_FREE_REPORT_PARTNER = "/astro/free-reports/partner";
export const GET_ASTRO_PANCHANG_HORA = "/astro/panchang/hora";
export const GET_ASTRO_PANCHANG_CHOGHADIYA = "/astro/panchang/choghadiya";
export const GET_ASTRO_ZODIAC_COMPATIBILITY = "/astro/compatibility/zodiac";
export const GET_ASTRO_LAL_KITAB_HOUSES = "/astro/lal-kitab/houses";
export const GET_ASTRO_LAL_KITAB_PLANETS = "/astro/lal-kitab/planets";
export const GET_ASTRO_CALCULATOR_LOVE = "/astro/calculators/love";
export const GET_ASTRO_CALCULATOR_FRIENDSHIP = "/astro/calculators/friendship";
export const ASTRO_PROFILE = "/astro/profile-forms";
export const GET_VCARDS_DATA = "/random-match/wallet-balance";

// auth routes
export const SEND_OTP = "/users/request-otp";
export const VERIFY_OTP = "/users/verify-otp";
export const SIGNUP = "/users/register";
export const UPDATE_PROFILE_PIC = "/users/updateProfilePic";
export const ADD_CALL_REVIEW = "/privateCall/review";
export const GET_REVIEW_ITEMS = "/privateCall/reviewItems";
